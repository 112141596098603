import React from 'react';

/**
 * Parse a CSS string into a React.CSSProperties object
 * @param cssString
 */
export function parseCssString(cssString: string): React.CSSProperties {
    return cssString.split(';').reduce((acc, style) => {
        if (style.trim()) {
            const [property, value] = style.split(':');
            const camelCaseProperty = property.trim().replace(/-([a-z])/g, (g) => g[1].toUpperCase());
            acc[camelCaseProperty] = value.trim();
        }

        return acc;
    }, {} as React.CSSProperties);
}

export default parseCssString;
