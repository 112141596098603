import React, { useContext, useEffect, useRef, useState } from 'react';
import { skip, subscribeOn, tap } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';

import { useBreakpointMatch, useRenderEnv } from 'src/hooks/useBreakpoint';
import { Delay } from 'src/components/Helpers/Delay';
import { StaticSpinner } from 'src/components/Layout/Spinner';
import { useHistoryLocationObservable } from 'src/hooks/router-hooks';

import { Container } from '../Layout';
import { HeaderContext } from '../Header/Header';

import classes from './Nav.scss';

let NavQueryImport = React.lazy(() => import('./NavQuery'));

if (RENDER_ENV_SERVER) {
    NavQueryImport = require('./NavQuery').default;
}

export const Nav = React.memo(function Nav() {
    const { showNav, setShowNav } = useContext(HeaderContext);

    const outerRef = useRef<HTMLDivElement | null>(null);
    const { isServer, isBrowser } = useRenderEnv();
    const { isMob, isDesk } = useBreakpointMatch();

    /**
     * Close on location change
     */
    const location$ = useHistoryLocationObservable();

    useEffect(() => {
        const sub = location$
            .pipe(
                skip(1),
                subscribeOn(asyncScheduler),
                tap(() => setShowNav(false)),
            )
            .subscribe();
        return () => sub.unsubscribe();
    }, [location$, setShowNav]);

    const [overflow, setOverflow] = useState(true);

    return (
        <nav
            data-nav-top
            ref={outerRef}
            data-nav-active={String(showNav)}
            data-nav-overflow={String(overflow)}
            className={classes.nav}
            role="navigation"
            aria-expanded={showNav}
            aria-controls="nav-menu"
        >
            <Container className={classes.navContainer}>
                <section className={classes.navHolder}>
                    {isServer && <NavQueryImport />}
                    {isMob && isBrowser && showNav && <NavQueryLoaderMob setOverflow={setOverflow} />}
                    {isDesk && isBrowser && <NavQueryLoaderDesk />}
                </section>
            </Container>
        </nav>
    );
});

const LOADER = (
    <div className={classes.loader}>
        <StaticSpinner />
    </div>
);

const LazyNavHeader = React.lazy(() => import('./NavHeader'));
function NavQueryLoaderMob(props: { setOverflow: (value: boolean) => void }) {
    return (
        <Delay delay={300}>
            <React.Suspense fallback={LOADER}>
                <LazyNavHeader />
                <NavQueryImport setOverflow={props.setOverflow} />
            </React.Suspense>
        </Delay>
    );
}

function NavQueryLoaderDesk() {
    return (
        <React.Suspense fallback={LOADER}>
            <NavQueryImport />
        </React.Suspense>
    );
}
