import React from 'react';
import classnames from 'classnames';

import { ProductPrice, ProductType } from 'src/types/global-types';
import { Price, usePrice } from 'src/components/Helpers/Price';

import classes from './ProductMainPrice.scss';

type ProductMainPriceProps = {
    layout?: 'normal' | 'grid' | 'basket-item';
    price: ProductPrice;
    productType: ProductType;
    vat?: boolean;
};

export function ProductMainPrice(props: ProductMainPriceProps) {
    const { symbol } = usePrice(props.price.minimum_price, props.price.minimum_price, true);
    const isSamePrice = props.price.minimum_price === props.price.maximum_price;
    const isConfigSalePrice = props.price.minimum_price !== props.price.minimum_sale_price;

    const isSimple = props.productType === ProductType.SimpleProduct;
    const isConfigurable = props.productType === ProductType.ConfigurableProduct;

    return (
        <div className={classes.root} data-layout={props.layout}>
            {isSimple && (
                <>
                    <div className={classes.priceWrapper}>
                        {props.price.special_price && (
                            <Price
                                price={props.price.special_price}
                                priceIncl={props.price.special_price}
                                vat={props.vat}
                                className={classnames({
                                    [classes.price]: true,
                                })}
                            />
                        )}
                        {!props.price.special_price && (
                            <Price
                                price={props.price.minimum_price}
                                priceIncl={props.price.minimum_price}
                                vat={props.vat}
                                className={classnames({
                                    [classes.price]: true,
                                })}
                            />
                        )}
                        {props.price.price_per && (
                            <span className={[classes.pricePer].join(' ')}>{props.price.price_per}</span>
                        )}
                    </div>

                    {props.price.special_price && (
                        <Price
                            before="Was "
                            price={props.price.minimum_price}
                            priceIncl={props.price.minimum_price}
                            vat={props.vat}
                            className={classnames({
                                [classes.price]: true,
                                [classes.priceWas]: true,
                            })}
                        />
                    )}

                    {props.price.discount && (
                        <span
                            className={classnames({
                                [classes.price]: true,
                                [classes.priceSaving]: true,
                            })}
                        >
                            {typeof props.price.discount === 'string' && <>Save {props.price.discount}</>}
                            {typeof props.price.discount === 'number' && (
                                <>
                                    Save {symbol}
                                    {props.price.discount.toFixed(2)}
                                </>
                            )}
                        </span>
                    )}
                </>
            )}

            {isConfigurable && (
                /**
                 * If the product is a configurable product and the price is the same for all variants
                 * Then we only show the price once
                 */
                <>
                    {isSamePrice && (
                        <div>
                            <Price
                                price={props.price.minimum_price}
                                priceIncl={props.price.minimum_price}
                                vat={props.vat}
                                className={classnames({
                                    [classes.price]: true,
                                })}
                            />
                        </div>
                    )}

                    {!isSamePrice && (
                        /**
                         * If the product is a configurable product and the price is different for all variants
                         * Then we show the price range
                         */
                        <>
                            <div
                                className={classnames({
                                    [classes.priceRangeWas]: isConfigSalePrice,
                                })}
                            >
                                <Price
                                    price={props.price.minimum_price}
                                    priceIncl={props.price.minimum_price}
                                    vat={props.vat}
                                    className={classnames({
                                        [classes.price]: true,
                                    })}
                                />
                                –
                                <Price
                                    price={props.price.maximum_price}
                                    priceIncl={props.price.maximum_price}
                                    vat={props.vat}
                                    className={classnames({
                                        [classes.price]: true,
                                    })}
                                />
                            </div>

                            {isConfigSalePrice && props.price.minimum_sale_price && props.price.maximum_sale_price && (
                                /**
                                 * If the product is a configurable product and is on sale
                                 * Then we show a second price range
                                 */
                                <div
                                    className={classnames({
                                        [classes.priceConfigSaving]: true,
                                    })}
                                >
                                    <Price
                                        price={props.price.minimum_sale_price}
                                        priceIncl={props.price.minimum_sale_price}
                                        vat={props.vat}
                                        className={classnames({
                                            [classes.price]: true,
                                        })}
                                    />
                                    –
                                    <Price
                                        price={props.price.maximum_sale_price}
                                        priceIncl={props.price.maximum_sale_price}
                                        vat={props.vat}
                                        className={classnames({
                                            [classes.price]: true,
                                        })}
                                    />
                                </div>
                            )}
                        </>
                    )}

                    {props.price.price_per && (
                        <span
                            className={classnames({
                                [classes.pricePer]: true,
                                [classes.pricePerConfig]: true,
                            })}
                        >
                            From {props.price.price_per}
                        </span>
                    )}
                </>
            )}
        </div>
    );
}

ProductMainPrice.defaultProps = { vat: true, layout: 'normal' };
