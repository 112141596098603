import React from 'react';
import classnames from 'classnames';

import { parseCssString } from 'src/components/ProductLabels/utils/parseCssString';

import classes from './ProductLabel.scss';

export type ProductLabelLocation = 'listing' | 'search' | 'detail' | 'cart';

export type ProductLabelTextProps = {
    label: string;
    customizeCssContainer?: string;
    customizeCssLabel?: string;
    locationVariant?: ProductLabelLocation;
};

/**
 * Individual product label
 */
export function ProductLabelText(props: ProductLabelTextProps) {
    const styleContainerObject = parseCssString(props.customizeCssContainer ?? '');
    const styleTextObject = parseCssString(props.customizeCssLabel ?? '');

    return (
        <small
            style={styleContainerObject}
            className={classnames({
                [classes.productLabelText]: true,
                [classes.productLabelTextMicro]: props.locationVariant === 'search' || props.locationVariant === 'cart',
            })}
        >
            <span style={styleTextObject}>{props.label}</span>
        </small>
    );
}
