import React, { useEffect } from 'react';

import { ProductPrice } from 'src/types/global-types';
import classes from 'src/components/AtbModal/AddToBasketModal.scss';
import { ProductBlock } from 'src/components/AtbModal/ProductBlock';
import { ButtonLink } from 'src/components/Button/ButtonLink';
import { imagePath } from 'src/util/formatUrl';
import { ConfigurableCartSelections } from 'src/components/Basket/ConfigurableCartSelections';

import type { AddToBasketDetails, ModalEventProps } from '../types';

import { AddToBasketModalTotals } from './AddToBasketModalTotals';

type AddToBasketModalContentProps = Pick<ModalEventProps, 'onClose'> &
    Pick<AddToBasketDetails, 'addedQuantity' | 'qty'> & {
        prices?: ProductPrice;
        productData: {
            image: string;
            name: string;
            sku: string;
            qty: number;
            configurableOptions?: any;
        };
        totalPriceAddedProducts: number;
        totalPriceAddedProductsInclVat: number;
        vat: boolean;
    };

export const AddToBasketModalContent = (props: AddToBasketModalContentProps) => {
    const { image, name, sku, qty, configurableOptions } = props.productData;

    useEffect(() => {
        if (image) {
            const productImage = document.querySelector(
                `.${classes['addToBasketModalBody']} img:first-of-type`,
            ) as HTMLImageElement;
            const newImageUrl = image;
            const currentImageUrl = productImage.src;

            if (productImage && newImageUrl !== currentImageUrl) {
                productImage.setAttribute('src', imagePath(newImageUrl));
            }
        }
    }, [image]);

    const options =
        Array.isArray(configurableOptions) &&
        configurableOptions?.map((option) => {
            return <ConfigurableCartSelections key={option.id} option={option} />;
        });

    return (
        <div className={classes['addToBasketModalBody']}>
            <ProductBlock product={{ image, name, sku, qty }} variant="large" options={options} />
            <AddToBasketModalTotals {...props} />
            <div className={classes['addToBasketActions']}>
                <ButtonLink
                    data-test-id="ATB_Modal__Checkout"
                    onClick={() => props.onClose?.()}
                    to="/basket"
                    variant="primary"
                >
                    Checkout
                </ButtonLink>
            </div>
        </div>
    );
};
