import type { CamelCase } from 'type-fest';
import toCamelCase from 'lodash.camelcase';

import type { storeConfig, storeConfig_cmsBlocks_items } from 'src/queries/__generated__/storeConfig';

/**
 * List of CMS block identifiers to fetch in the store config.
 *
 * This is a read-only array which is used to automatically
 * generate types.
 */
const cmsBlockIdentifiers = [
    'global-banner',
    'click_and_collect__available_message__basket',
    'click_and_collect__available_message__checkout_success',
    'click_and_collect__available_message__checkout',
    'click_and_collect__available_message__no_lead_time__basket',
    'click_and_collect__available_message__no_lead_time__checkout_success',
    'click_and_collect__available_message__no_lead_time__checkout',
    'click_and_collect__available_message__no_lead_time__product_detail',
    'click_and_collect__available_message__no_lead_time',
    'click_and_collect__available_message__product_detail',
    'click_and_collect__available_message',
    'click_and_collect__unavailable_message',
    'home_delivery__available_message',
    'home_delivery__unavailable_message',
] as const;

/**
 * A runtime friendly version of the `cmsBlockIdentifiers` array.
 */
export const globalCmsBlockIdentifiers = cmsBlockIdentifiers.slice();

type CmsBlockIdentifiers = (typeof cmsBlockIdentifiers)[number];

/**
 * CMS blocks fetched from the store config.
 *
 * Automatically generated from cmsBlockIdentifiers.
 */
export type GlobalCmsBlocks = {
    [K in CmsBlockIdentifiers as CamelCase<K>]?: string;
};

function cmsBlockByIndentifier(globalConfig: storeConfig | undefined, identifier: string): string | undefined {
    if (!globalConfig || !globalConfig.cmsBlocks || !globalConfig.cmsBlocks?.items) {
        return undefined;
    }

    const items = globalConfig.cmsBlocks.items as storeConfig_cmsBlocks_items[];
    const match = items.find((block) => block?.identifier === identifier);

    return match?.content || undefined;
}

/**
 * Parse CMS blocks from the store config data.
 */
export function parseGlobalCmsBlocks(data: storeConfig | undefined): GlobalCmsBlocks {
    return cmsBlockIdentifiers.reduce((acc, identifier) => {
        const camelCaseIdentifier = toCamelCase(identifier);
        acc[camelCaseIdentifier] = cmsBlockByIndentifier(data, identifier);

        return acc;
    }, {});
}
