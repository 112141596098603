import type { CartItemPrice, CartProduct } from '@wearejh/m2-pwa-cart-gql';

import { imagePath } from 'src/util/formatUrl';
import { productPriceFromInt } from 'src/types/global-types';

import type { AddToBasketDetails, AtbCartItem } from '../types';

export interface UseGetATBDetailsInterface {
    item?: AtbCartItem;
    prices?: CartItemPrice;
    product?: CartProduct;
    quantity?: number;
}

export const useGetAddToBasketProductDetails = (props: UseGetATBDetailsInterface): AddToBasketDetails => {
    let productImage: string | undefined;

    if (props.item?.__typename === 'ConfigurableCartItem') {
        productImage = imagePath(props.item?.configured_variant?.small_image?.url);
    } else {
        productImage = imagePath(props.product?.small_image.url);
    }

    return {
        ...props.item,
        image: productImage ?? undefined,
        name: props.product?.name ?? undefined,
        qty: props?.quantity ?? undefined,
        singleProductPrice: props?.prices ? productPriceFromInt(props.prices.price) : undefined,
        sku: props.product?.sku ?? undefined,
        totalPrice: props?.prices ? productPriceFromInt(props?.prices?.row_total_including_tax) : undefined,
        configurableOptions:
            props.item?.__typename === 'ConfigurableCartItem' ? props.item.configurable_options : undefined,
    };
};

export default useGetAddToBasketProductDetails;
