import React from 'react';

import { LazyImg } from 'src/hooks/useLazyLoad';

import classes from './ProductLabel.scss';

export type ProductLabelImageProps = {
    imageAlt: string;
    imageSrc: string;
};

/**
 * Individual product image label
 */
export function ProductLabelImage(props: ProductLabelImageProps) {
    return <LazyImg className={classes.productLabelImage} alt={props.imageAlt} src={props.imageSrc} />;
}
