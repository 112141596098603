type MutationSelector = (element: HTMLElement) => boolean;
type MutationCallback = (element: HTMLElement) => void;

/**
 * Configuration options for the DOM observer.
 *
 * @property {MutationCallback} [onMount] - Callback function to be called when an element matching the selector is added to the DOM.
 * @property {MutationCallback} [onUnmount] - Callback function to be called when an element matching the selector is removed from the DOM.
 * @property {MutationObserverInit} [options] - Options for the MutationObserver.
 * @property {MutationSelector} selector - Function to determine if an element should be observed.
 */
export type ObserverConfig = {
    onMount?: MutationCallback;
    onUnmount?: MutationCallback;
    options?: MutationObserverInit;
    selector: MutationSelector;
};

function isHTMLElement(node: Node): node is HTMLElement {
    return node.nodeType === Node.ELEMENT_NODE;
}

/**
 * Represents an entry in the observer map.
 *
 * @property {MutationObserver} observer - The MutationObserver instance.
 * @property {ObserverConfig} config - The configuration for the observer.
 */
type ObserverEntry = {
    observer: MutationObserver;
    config: ObserverConfig;
};

/**
 * Observer the HTML DOM for changes.
 *
 * This is a singleton class that ensures only one instance of an observer is
 * created. If an observer already exists for a target element, it will
 * be reused.
 *
 * @example
 * ```ts
 * const observer = DomObserver.getInstance();
 * observer.observe(document.body, {
 *    onMount: (element) => console.log('Element added:', element),
 *    onUnmount: (element) => console.log('Element removed:', element),
 *    selector: (element) => element.classList.contains('my-class'),
 * });
 *
 * observer.stop(target);
 * ```
 */
export class DomObserver {
    private static instance: DomObserver;
    private readonly observerMap: Map<Element, ObserverEntry> = new Map();

    private constructor() {}

    /**
     * Gets the singleton instance of the DomObserver.
     *
     * @returns {DomObserver} - The singleton instance.
     */
    public static getInstance(): DomObserver {
        if (!DomObserver.instance) {
            DomObserver.instance = new DomObserver();
        }

        return DomObserver.instance;
    }

    /**
     * Starts observing a target element for mutations.
     *
     * @param {Element} target - The target element to observe.
     * @param {ObserverConfig} config - The configuration for the observer.
     */
    public observe(target: Element, config: ObserverConfig) {
        const {
            options = {
                attributes: true,
                characterData: true,
                childList: true,
                subtree: true,
            },
            selector,
        } = config;

        if (this.observerMap.has(target)) {
            const observer = this.observerMap.get(target)?.observer;

            if (observer) {
                this.observerMap.set(target, {
                    observer,
                    config,
                });
            }

            return;
        }

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type !== 'childList') {
                    return;
                }

                mutation.addedNodes.forEach((node) => {
                    if (isHTMLElement(node) && selector(node)) {
                        this.observerMap.get(target)?.config.onMount?.(node);
                    }
                });

                mutation.removedNodes.forEach((node) => {
                    if (isHTMLElement(node) && selector(node)) {
                        this.observerMap.get(target)?.config.onUnmount?.(node);
                    }
                });
            });
        });

        observer.observe(target, options);
        this.observerMap.set(target, { observer, config });
    }

    /**
     * Stops observing a target element for mutations.
     *
     * @param {Element} target - The target element to stop observing.
     */
    public stop(target: Element) {
        if (this.observerMap.has(target)) {
            this.observerMap.get(target)?.observer.disconnect();
            this.observerMap.delete(target);
        }
    }
}
