import type { ReactPortal } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { useDomObserver } from '../../hooks/dom-observer';

import { NostoPlacementWrapper } from './NostoPlacementWrapper';
import { getPlacementId, getRecommendations, isDynamicPlacement } from './utilities/dynamic-placements';

type DynamicPlacementPortals = Map<string, ReactPortal>;

/**
 * Injects dynamic Nosto placements into the DOM.
 *
 * Note: It's vital that dynamic placements use the
 * "custom-dynamic-placement-components" template in the Nosto dashboard.
 *
 * Nosto dynamic placements can be added/remove from the DOM at any time.
 * In order to render our React components, we need to watch for these
 * changes and create portals for each placement.
 *
 * Portals are used to render our React components outside of the normal
 * React tree. This allows us to render our components in the correct
 * placement without having to worry about the placement's parent.
 * We can be conifdent that features like "Add to basket"  will still work,
 * regardless of where the Nosto dynamic placement is placed.
 */
export function NostoDynamicPlacements() {
    /**
     * Store an array of portals for each dynamic placement and use a
     * key to ensure the placement is unique.
     *
     * A Map ensures that only one instace of the placement
     * "hello-world" exists.
     */
    const [portals, setPortals] = useState<DynamicPlacementPortals>(new Map());

    /**
     * When a Nosto dynamic placement is added to the HTML Dom, render our
     * React components in the placement.
     */
    const onMount = useCallback((element: HTMLElement) => {
        const id = getPlacementId(element);
        const recommendations = getRecommendations(element);

        if (!id || !recommendations) {
            return;
        }

        const portal = createPortal(
            <NostoPlacementWrapper nostoRecommendations={recommendations} type="other" />,
            element,
            id,
        );

        setPortals((prevPortals) => {
            const portals = new Map(prevPortals);

            portals.set(id, portal);

            return portals;
        });
    }, []);

    /**
     * When a Nosto dynamic placement is removed from the HTML Dom, ensure
     * it's removed from our portals array.
     * This prevents a placement from being rendered after it's been
     * removed from the DOM.
     */
    const onUnmount = useCallback((element) => {
        const id = getPlacementId(element);

        if (!id) {
            return;
        }

        setPortals((prevPortals) => {
            const portals = new Map(prevPortals);
            portals.delete(id);

            return portals;
        });
    }, []);

    /**
     * Watch the DOM for Nosto dynamic placements.
     * It will only call the onMount and onUnmount functions when a Nosto
     * dynamic placement is added/removed.
     */
    useDomObserver(document.body, {
        onMount,
        onUnmount,
        selector: isDynamicPlacement,
    });

    const renderPortals = useMemo(() => {
        return Array.from(portals.values());
    }, [portals]);

    return <div id="nosto-dynamic-placement-portals">{renderPortals}</div>;
}

export default NostoDynamicPlacements;
