import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import getLabelDataQuery from 'src/queries/getLabelData.graphql';
import { useDeps } from 'src/hooks/useDeps';
import { getLabelData, getLabelDataVariables } from 'src/queries/__generated__/getLabelData';
import { ProductLabelsTypeProps } from 'src/components/ProductLabels/ProductLabelsType';
import { ProductLabelLocation } from 'src/components/ProductLabels/components';

export type LazyProductLabelsListProps = {
    labelId: number;
    locationVariant?: ProductLabelLocation;
};

/**
 * Group of product labels
 */
export function LazyProductLabelsList(props: LazyProductLabelsListProps) {
    const { loading, error, data } = useQuery<getLabelData, getLabelDataVariables>(getLabelDataQuery, {
        variables: {
            labelId: props.labelId,
        },
        ssr: useDeps().env.SSR_GQL,
    });

    if (error) {
        console.error('LazyProductLabelsList:', error);
    }

    if (loading || !data?.getLabelData || !data.getLabelData[0] || !data.getLabelData[0]?.type) {
        return null;
    }

    const label = data.getLabelData[0];

    return (
        <React.Suspense fallback={null}>
            <LazyProductLabelsType
                key={label.label_id ?? label.name}
                label={label}
                locationVariant={props.locationVariant}
            />
        </React.Suspense>
    );
}

const LazyProductLabelsType: React.FC<ProductLabelsTypeProps> = React.lazy(() => import('./ProductLabelsType'));
