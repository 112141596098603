import { ProductListItem } from 'src/components/CategoryListingPage/utils/productListItem';
import { ProductPrice, ProductType } from 'src/types/global-types';
import { formatNostoPrices } from 'src/components/Nosto/utilities/formatNostoPrices';
import { ProductLabelData } from 'src/components/ProductLabels/utils/cleanLabelData';

import type { NostoProductDataRaw, NostoProductData } from '../types';

const truncate = (content: string, maxLength: number) => {
    if (content.length > maxLength) {
        return content.substring(0, maxLength - 3) + '...';
    }

    return content;
};

const findDataInTags = (string: string, tags: string[]) => {
    let result = '';

    tags.forEach((tag) => {
        if (tag.includes(string)) {
            result = tag.split(':')[1];
        }
    });

    return result;
};

export const formatProducts = (products: NostoProductDataRaw[] | undefined): NostoProductData[] => {
    if (!Array.isArray(products)) return [];

    const cleanedNostoProducts: NostoProductData[] = [];

    products.map((product) => {
        const urlWithoutDomain = product.url.split('woodies.ie')?.[1];
        const minimumPrice = product.list_price;
        const specialPrice = product.price ?? undefined;

        // The type of the product is not exposed in the Nosto API
        // As a workaround, we can check if the product has skus to determine if it is configurable
        const isConfigurable = (product.skus?.length ?? 0) > 0;

        const clickAndCollect = findDataInTags('click_and_collected', product.tags1);
        const homeDelivery = findDataInTags('delivery_type', product.tags1);
        const isHomeDelivery = homeDelivery ? homeDelivery !== '-' : false;
        const isClickAndCollect = clickAndCollect === 'Yes';
        const sku = product.custom_fields?.sku ?? product.tags1.find((tag) => tag.includes('sku'))?.split(':')[1] ?? '';

        let price: ProductPrice = {
            minimum_price: minimumPrice,
            maximum_price: minimumPrice,
            minimum_sale_price: undefined,
            maximum_sale_price: undefined,
            special_price: undefined,
            discount: undefined,
        };

        if (isConfigurable) {
            price = formatNostoPrices(product.skus);
        } else if (specialPrice !== minimumPrice) {
            price.minimum_price = minimumPrice;
            price.special_price = specialPrice;
            // TODO: We should not be handling price calculations here. BE will need to expose this to nosto as a field
            price.discount = minimumPrice - specialPrice;
        }

        let labels: ProductLabelData[] = [];

        /**
         * We know that the coming soon label UI matches the labels UI
         * Rather than duplicating a component we can just replace the label data with the coming soon label if it exists
         */
        const isComingSoon = product.custom_fields?.is_coming_soon === 'Yes';

        if (isComingSoon) {
            labels = [
                {
                    label_id: 'coming-soon',
                    name: 'Coming soon',
                    customize_css_container_large: 'background-color: #6D7B83;',
                    type: 'text',
                },
            ];
        }

        const formattedProduct: ProductListItem = {
            type: isConfigurable ? ProductType.ConfigurableProduct : ProductType.SimpleProduct,
            id: product.product_id ?? '',
            name: product.name ?? '',
            subTitle: product?.custom_fields?.subtitle,
            description: product.description ? truncate(product.description, 50) : '',
            url: urlWithoutDomain ?? product.url ?? '/',
            price,
            small_image: {
                label: product.name ?? '',
                url: product.image_url ?? '',
            },
            click_collect: isComingSoon ? false : isClickAndCollect,
            delivery: isComingSoon ? false : isHomeDelivery,
            sku,
            labels,
            rating_value: product.rating_value,
            review_count: product.review_count,
            isComingSoon,
        };

        cleanedNostoProducts.push(formattedProduct);
    });

    return cleanedNostoProducts;
};

export default formatProducts;
