import React from 'react';
import { CartItem } from '@wearejh/m2-pwa-cart-gql';
import { Link } from 'react-router-dom';

import { ProductSummary } from 'src/components/Basket/utils/cartItemToProductSummary';
import { Price } from 'src/components/Helpers/Price';
import { LazyImg } from 'src/hooks/useLazyLoad';
import { imagePath } from 'src/util/formatUrl';

import classes from './ProductBlock.scss';

type ProductBlockProps = {
    afterName?: React.ReactNode;
    options?: React.ReactNode;
    product: ProductSummary;
    variant?: 'large' | 'normal' | 'small';
};

export function ProductBlock(props: ProductBlockProps) {
    const { image, name, pathname } = props.product;

    return (
        <div className={classes.productBlock} data-variant={props.variant}>
            <figure className={classes.productBlockImage}>
                <LazyImg src={imagePath(image)} alt={name} />
            </figure>
            <div className={classes.productBlockDetail}>
                {pathname && (
                    <Link className={classes.productBlockTitle} to={`/${pathname}`} id="atb_modal_desc">
                        {name}
                    </Link>
                )}

                {!pathname && (
                    <p className={classes.productBlockTitle} id="atb_modal_desc">
                        {name}
                    </p>
                )}

                {props.options && <div className={classes.productBlockOptions}>{props.options}</div>}
                {props.afterName}
            </div>
        </div>
    );
}

ProductBlock.defaultProps = { variant: 'normal' };

export function ProductQtyPrice(props: { cartItem: CartItem }) {
    return (
        <div className={classes.itemPrice}>
            <span>Qty: {props.cartItem.quantity}</span>
            <span>
                <Price
                    price={props.cartItem.prices.row_total}
                    priceIncl={props.cartItem.prices.row_total_including_tax}
                    vat={false}
                />
            </span>
        </div>
    );
}
