import { ProductHitLabelData } from 'src/components/Algolia/utils/algolia.types';
import { ProductLabelData } from 'src/components/ProductLabels/utils/cleanLabelData';

/**
 * Get all product labels from the hit and convert them into an array of label_ids
 * This helps to avoid duplicates
 * With algolia we need to make a second query using the ID as a variable to get the label data which is done lazily.
 * @param groupArray
 */
export function getHitProductLabels(groupArray: any[][]): ProductLabelData[] {
    const labelsArray: ProductLabelData[] = [];

    groupArray.forEach((labels: ProductHitLabelData[]) => {
        labels.forEach((label) => {
            if (
                !label ||
                !label.label_id ||
                labelsArray.some((productLabel) => productLabel.label_id === label.label_id)
            ) {
                return;
            }

            labelsArray.push({ label_id: label.label_id });
        });
    });

    return labelsArray;
}

export default getHitProductLabels;
