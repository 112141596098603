import { useCallback, useMemo } from 'react';

import { fireGTMEvent, useGTM } from 'src/features/analytics/hooks/useGtm';

import { ProductListItem } from '../utils/productListItem';

type Params = {
    products: ProductListItem[];
    listName?: string;
    listId?: string;
};

/**
 * GTM events for the product list
 * @param params
 */
export function useProductListGtm(params: Params) {
    const { listId, listName, products } = params;

    /**
     * The event to fire only once
     */
    const itemEvent = useMemo(() => {
        return {
            event: 'view_item_list' as const,
            ecommerce: {
                items: products.map((item, index) => {
                    return {
                        item_id: item.id.toString(),
                        item_name: item.name,
                        value: item.price.minimum_price,
                        index: index,
                    };
                }),
                item_list_name: listName,
                item_list_id: listId,
            },
        };
    }, [listId, listName, products]);

    /**
     * Fire the one-time event
     */
    useGTM(itemEvent);

    const gtmSelectItem = useCallback(
        (index: number) => {
            const product = products[index];
            fireGTMEvent({
                event: 'select_item',
                ecommerce: {
                    items: [
                        {
                            item_id: product.id.toString(),
                            item_name: product.name,
                            value: product.price.minimum_price,
                            index: index,
                        },
                    ],
                    item_list_name: listName,
                    item_list_id: listId,
                },
            });
        },
        [listId, listName, products],
    );

    return {
        gtmSelectItem,
    };
}
