import { useEffect } from 'react';

import type { ObserverConfig } from './dom-observer';
import { DomObserver } from './dom-observer';

/**
 * Watch the HTML DOM for changes.
 *
 * Use the `selector` parameter to specify which elements to watch for.
 * Only when the selector matches an element will the `onMount` and `onUnmount` functions be called.
 */
export function useDomObserver(target: Element, config: ObserverConfig) {
    useEffect(() => {
        const observer = DomObserver.getInstance();
        observer.observe(target, config);

        return () => observer.stop(target);
    }, [target, config]);
}
