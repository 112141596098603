import React, { useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import { useVatCausesRender } from 'src/components/AppShell/VatProvider';
import styles from 'src/components/Modal/Modal.scss';
import { Nosto } from 'src/components/Nosto/Nosto';

import classes from './AddToBasketModal.scss';
import type { AddToBasketDetails, ModalEventProps } from './types';
import {
    AddToBasketModalOverlay,
    AddToBasketModalHeader,
    AddToBasketModalFooter,
    AddToBasketModalContent,
} from './InnerComponents';

type AddToBasketModalProps = ModalEventProps & {
    detail?: AddToBasketDetails;
};

const AddToBasketModalContentWrapper = (props: AddToBasketModalProps) => {
    const { sku, image, name, qty, singleProductPrice, addedQuantity, configurableOptions } = props.detail ?? {};
    const { vat } = useVatCausesRender();
    const allowedScrollEl = useRef<HTMLDivElement>(null);

    const productData = {
        image: image ?? '',
        name: name ?? '',
        sku: sku ?? '',
        qty: qty ?? 0,
        configurableOptions: configurableOptions ?? undefined,
    };

    const { totalPriceAddedProducts, totalPriceAddedProductsInclVat } = useMemo(() => {
        let totalPriceAddedProducts = 0;
        let totalPriceAddedProductsInclVat = 0;

        if (singleProductPrice && addedQuantity) {
            totalPriceAddedProducts = singleProductPrice?.minimum_price * addedQuantity;
            totalPriceAddedProductsInclVat = singleProductPrice?.minimum_price * addedQuantity;
        }

        return {
            totalPriceAddedProducts,
            totalPriceAddedProductsInclVat,
        };
    }, [addedQuantity, singleProductPrice]);

    useEffect(() => {
        const refEl = allowedScrollEl.current;

        if (refEl && props.isOpen) {
            disablePageScroll(refEl);
        }

        if (refEl && !props.isOpen) {
            enablePageScroll(refEl);
        }

        return () => {
            if (refEl) {
                enablePageScroll(refEl);
            }
        };
    }, [props.isOpen]);

    return (
        <div
            className={classnames({
                [classes['addToBasketWrapper']]: true,
                [styles['modalWrapper']]: true,
                [styles['modalWrapperIsOpen']]: props.isOpen,
            })}
        >
            <AddToBasketModalOverlay ref={allowedScrollEl}>
                <AddToBasketModalHeader onClose={() => props.onClose?.()} />
                <AddToBasketModalContent
                    {...props}
                    productData={productData}
                    qty={qty}
                    addedQuantity={addedQuantity}
                    totalPriceAddedProducts={totalPriceAddedProducts}
                    totalPriceAddedProductsInclVat={totalPriceAddedProductsInclVat}
                    vat={vat}
                    prices={singleProductPrice}
                />
                <div className={classes['addToBasketNostoWrapper']}>
                    <Nosto type="addToBasket" variant="modal" />
                </div>
                <AddToBasketModalFooter onClose={() => props.onClose?.()} />
            </AddToBasketModalOverlay>
        </div>
    );
};

export const AddToBasketModal = (props: AddToBasketModalProps) => {
    const { detail } = props;

    if (typeof document !== 'undefined' && detail) {
        return createPortal(<AddToBasketModalContentWrapper {...props} />, document.body, 'AddToBasketModal');
    }

    return null;
};
