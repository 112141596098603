import React from 'react';
import classnames from 'classnames';

import { ProductLabelData } from 'src/components/ProductLabels/utils/cleanLabelData';
import { ProductLabelsType } from 'src/components/ProductLabels/ProductLabelsType';
import { LazyProductLabelsList } from 'src/components/ProductLabels/LazyProductLabelsList';
import { ProductLabelLocation } from 'src/components/ProductLabels/components';

import classes from './ProductLabelsList.scss';

export type ProductLabelsListProps = {
    className?: string;
    direction?: 'column' | 'row';
    labels?: ProductLabelData[];
    isLazy?: boolean;
    locationVariant?: ProductLabelLocation;
};

/**
 * Group of product labels
 */
export function ProductLabelsList(props: ProductLabelsListProps) {
    if (!props.labels || props.labels.length === 0) {
        return null;
    }

    /**
     * Labels can either be lazy loaded or rendered immediately.
     * Algolia and Nosto use lazy loading to improve performance. The data is just an array of label_id's which we then use to make a separate query to fetch the full label data.
     * Whereas labels on the product detail/listing page are rendered immediately, the full data is returned by magento.
     * However, the coming soon label is always rendered immediately and needs to work in both scenarios.
     */
    return (
        <div className={classnames(classes.productLabelsList__container, props.className)}>
            <ul className={classes.productLabelsList} data-direction={props.direction}>
                {props.labels.map((label) => {
                    /**
                     * label_id is typically a number
                     * If the label is coming soon, we want to render it immediately avoiding lazy loading
                     */
                    if (label.label_id === 'coming-soon') {
                        return (
                            <ProductLabelsType
                                key={label.label_id ?? label.name}
                                label={label}
                                locationVariant={props.locationVariant}
                            />
                        );
                    }

                    if (props.isLazy && label.label_id && typeof label.label_id === 'number') {
                        return (
                            <LazyProductLabelsList
                                key={label.label_id ?? label.name}
                                labelId={label.label_id}
                                locationVariant={props.locationVariant}
                            />
                        );
                    }

                    return (
                        <ProductLabelsType
                            key={label.label_id ?? label.name}
                            label={label}
                            locationVariant={props.locationVariant}
                        />
                    );
                })}
            </ul>
        </div>
    );
}
