import React from 'react';
import { CartConfigOption } from '@wearejh/m2-pwa-cart-gql';

type ConfigurableCartSelectionsProps = {
    option: CartConfigOption;
};

export function ConfigurableCartSelections(props: ConfigurableCartSelectionsProps) {
    return (
        <>
            <span>
                {props.option.option_label}: {props.option.value_label}
            </span>{' '}
            <br />
        </>
    );
}
