import { NostoRecommendations, NostoRecommendationsRaw } from '../types';

export const normalizeNostoRecommendations = (recommendations: NostoRecommendationsRaw): NostoRecommendations => {
    const content = Object.entries(recommendations?.campaigns?.content ?? {})?.map((campaign) => ({
        campaign_id: campaign[0] ?? undefined,
        div_id: campaign[1].div_id ?? undefined,
        html: campaign[1].html ?? undefined,
        params: campaign[1].params ?? undefined,
        result_id: campaign[1].result_id,
    }));

    const recommendationArray = Object.entries(recommendations?.campaigns?.recommendations ?? {})?.map((rec) => ({
        placement_id: rec[0],
        result_id: rec[1].result_id ?? undefined,
        products: rec[1].products ?? undefined,
        result_type: rec[1].result_type ?? undefined,
        title: rec[1].title ?? undefined,
        div_id: rec[1].div_id ?? undefined,
        source_product_ids: rec[1].source_product_ids ?? undefined,
        params: rec[1].params ?? undefined,
    }));

    return {
        content,
        recommendations: recommendationArray,
    };
};

export default normalizeNostoRecommendations;
