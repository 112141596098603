import type { ProductPrice } from 'src/types/global-types';
import type { NostoSku } from 'src/components/Nosto/types';

/**
 * Format Nosto prices
 * Nosto doesn't return the same price structure as Magento and we need to show a range of prices
 * To do this we need to loop over all of the children and find the highest and lowest prices
 * @param skus
 */
export function formatNostoPrices(skus: NostoSku[]): ProductPrice {
    let minimum_price = skus[0]?.list_price;
    let maximum_price = skus[0]?.list_price;
    let minimum_sale_price = skus[0]?.price;
    let maximum_sale_price = skus[0]?.price;

    skus.forEach((sku) => {
        if (sku.list_price < minimum_price) {
            minimum_price = sku.list_price;
        }
        if (sku.list_price > maximum_price) {
            maximum_price = sku.list_price;
        }
        if (sku.price < minimum_sale_price) {
            minimum_sale_price = sku.price;
        }
        if (sku.price > maximum_sale_price) {
            maximum_sale_price = sku.price;
        }
    });

    return {
        minimum_price,
        maximum_price,
        minimum_sale_price: minimum_price === minimum_sale_price ? undefined : minimum_sale_price,
        maximum_sale_price: maximum_price === maximum_sale_price ? undefined : maximum_sale_price,
    };
}
