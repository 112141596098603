import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@wearejh/rx-form/lib/RxForm';

import { LazyImg } from 'src/hooks/useLazyLoad';
import { Button } from 'src/components/Button/Button';
import { Svg } from 'src/components/Svg/Svg';
import { ProductMainPrice } from 'src/components/ProductDetail/ProductMainPrice';
import { ReviewCount } from 'src/components/Reviews/ReviewCount';
import { imagePath } from 'src/util/formatUrl';
import { useDeps } from 'src/hooks/useDeps';
import { ProductListDeliveryMethod } from 'src/components/CategoryListingPage/ProductListDeliveryMethod';
import { ProductLabelsList } from 'src/components/ProductLabels/ProductLabelsList';

import classes from '../CategoryListingPage/ProductList.scss';

import type { NostoProductData } from './types';

export type NostoProductCardProps = {
    product: NostoProductData;
    onClick?: () => void;
    onAddToBasket: (product: NostoProductData) => void;
    slotRef?: string;
    isDisabled: boolean;
};

export const NostoProductCard = (props: NostoProductCardProps) => {
    const { name, url, small_image, price, rating_value, review_count, labels, subTitle, isComingSoon } = props.product;
    const { NOSTO_START_REVIEWS } = useDeps().env;
    const hasLabels = labels.length > 0;

    return (
        <div className={classes.content} data-variant="nosto">
            <Link to={`${url}?nosto=${props.slotRef}`} className={classes.image} onClick={props.onClick}>
                <LazyImg key={small_image.url} src={imagePath(small_image.url)} alt={small_image.label} />
            </Link>
            <div className={classes.productListInfo}>
                {!isComingSoon && (
                    <Form
                        onSubmit={() => {
                            props.onAddToBasket(props.product);
                        }}
                    >
                        <fieldset disabled={props.isDisabled}>
                            <Button
                                type="submit"
                                variant="primary"
                                icon="left-of-text"
                                width="full"
                                className={classes.productListAtb}
                            >
                                <Svg name="basket" />
                                <span>Add to Cart</span>
                            </Button>
                        </fieldset>
                    </Form>
                )}
                {hasLabels && (
                    <ProductLabelsList
                        className={classes.productListItemLabels}
                        direction="column"
                        labels={labels}
                        isLazy={true}
                        locationVariant="listing"
                    />
                )}
                <Link to={`${url}?nosto=${props.slotRef}`} className={classes.productListTitle} onClick={props.onClick}>
                    {name}
                </Link>

                {NOSTO_START_REVIEWS && review_count && rating_value && (
                    <ReviewCount
                        starCount={rating_value}
                        reviewCount={review_count}
                        canWrite={false}
                        showTotal={false}
                    />
                )}

                <span className={classes.productListPrice}>
                    <ProductMainPrice productType={props.product.type} price={price} vat={true} layout="grid" />
                </span>

                {subTitle && <strong className={classes.productListSubTitle}>{subTitle}</strong>}

                <ProductListDeliveryMethod product={props.product} />
            </div>
        </div>
    );
};

export default NostoProductCard;
