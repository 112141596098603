import { hasRecommendationsData } from './recommendations';

export function getPlacementId(element: HTMLElement): string | null {
    return element.id;
}

export function isDynamicPlacement(element: HTMLElement): boolean {
    const hasClassname = element.classList.contains('nosto-dynamic-placement');
    const hasPlacementId = getPlacementId(element);

    if (!hasClassname || !hasPlacementId || !hasRecommendationsData(element)) {
        return false;
    }

    return true;
}
