import React, { memo } from 'react';

import classes from './HeaderControls.scss';

interface BasketBadgeProps {
    /** Label to display in badge */
    label: string;
}

/**
 * Badge displayed on `<BasketIcon />`
 */
export function BasketBadge(props: BasketBadgeProps) {
    const { label } = props;

    return (
        <span aria-hidden="true" className={classes.basketBadge}>
            <span data-test-id="cart-count" className={classes.basketBadge__wrapper}>
                {label}
            </span>
        </span>
    );
}

/**
 * A memorized (`React.memo()`) version of `<BasketBadge />`
 * to provide performance enhancements.
 *
 * Use `<BasketBadge />` if you don't want to use the
 * memorized version.
 */
export const BasketBadgeMemo = memo(BasketBadge);
