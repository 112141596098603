import { Observable } from 'rxjs';
import { CartActions, CartItem, CartState, TypeMap } from '@wearejh/m2-pwa-cart-gql';
import { ofType } from 'redux-observable';
import { finalize, map, pluck, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { debug } from 'src/components/AtbModal/ProductAddToBasketModal';
import { Events } from 'src/components/AtbModal/types';

import type { AtbCartItems } from '../types';

function findAddedOrIncreasedItems(currentCartItems: CartItem[], prevCartItems: CartItem[]): AtbCartItems {
    return currentCartItems
        .filter((currentItem) => {
            const previousItem = prevCartItems.find(({ id }) => id === currentItem.id);

            const isNewItem = !previousItem;
            const hasQuantityIncreased = previousItem && currentItem.quantity > previousItem.quantity;

            return isNewItem || hasQuantityIncreased;
        })
        .map((currentItem) => {
            const previousItem = prevCartItems.find(({ id }) => id === currentItem.id);
            let addedQuantity = currentItem.quantity;

            if (previousItem) {
                addedQuantity = currentItem.quantity - previousItem.quantity;
            }

            return {
                ...currentItem,
                addedQuantity,
            };
        });
}

export function addSuccess(action$: Observable<any>, state$: Observable<any>) {
    const before$: Observable<CartState['items']> = action$.pipe(
        ofType<CartActions>('Cart.Add', 'Cart.Add.Simple', 'Cart.Add.Config'),
        withLatestFrom(state$.pipe(pluck('cart', 'items')), (_, items) => items),
    );

    const current$: Observable<CartState['items']> = state$.pipe(pluck('cart', 'items'));

    return action$.pipe(
        ofType<CartActions, TypeMap['Cart.AddSuccess']>('Cart.AddSuccess'),
        switchMap(() => action$.pipe(ofType<CartActions>('Cart.FetchSuccess'), take(1))),
        withLatestFrom(before$, current$),
        map(([, prevCartItems, currentCartItems]: [any, CartState['items'], CartState['items']]) => {
            const itemsAdded = findAddedOrIncreasedItems(currentCartItems, prevCartItems);

            return {
                type: 'AddToBasketModal.Open',
                items: itemsAdded,
            } as Events;
        }),
        finalize(() => debug('addSuccess no longer listening')),
    );
}

export default addSuccess;
