import React from 'react';
import { useContext, useMemo } from 'react';

import { NostoContext } from 'src/components/Nosto';
import { NostoPlacementType } from 'src/components/Nosto/types';
import { PostsByArrayOfIDs } from 'src/wordpress/components/PostsByArrayOfIDs';

import { NostoDynamicPlacements } from './NostoDynamicPlacements';
import NostoPlacementWrapper from './NostoPlacementWrapper';

type NostoProps = {
    type: NostoPlacementType;
    variant?: 'normal' | 'modal';
    blogPostIds?: string[];
};

export function Nosto(props: NostoProps) {
    const { isLoaded, nostoRecommendations } = useContext(NostoContext);
    const recommendations = nostoRecommendations?.[props.type];

    const hasStaticPlacements = useMemo(() => recommendations && isLoaded, [isLoaded, recommendations]);

    return (
        <>
            {hasStaticPlacements && (
                <NostoPlacementWrapper
                    type={props.type}
                    nostoRecommendations={recommendations}
                    variant={props.variant}
                    blogPostIds={props.blogPostIds}
                />
            )}
            {!hasStaticPlacements && props?.blogPostIds && <PostsByArrayOfIDs postIds={props?.blogPostIds} />}
            {isLoaded && <NostoDynamicPlacements />}
        </>
    );
}
