import React from 'react';

import classes from 'src/components/AtbModal/AddToBasketModal.scss';
import { Button } from 'src/components/Button/Button';

import type { ModalEventProps } from '../types';

type AddToBasketModalFooterProps = Pick<ModalEventProps, 'onClose'>;

export const AddToBasketModalFooter = (props: AddToBasketModalFooterProps) => {
    return (
        <div className={classes['addToBasketModalFooter']}>
            <p className={classes['escape']}>
                <Button type="button" variant="reset" onClick={() => props.onClose?.()}>
                    Continue Shopping
                </Button>
            </p>
        </div>
    );
};
