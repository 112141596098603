import { decode } from 'html-entities';

import type { NostoRecommendations, NostoRecommendationsRaw } from 'src/components/Nosto/types';

import { normalizeNostoRecommendations } from '..';

const dynamicPlacementAttribute = 'data-nosto-dynamic-placement';

export function getRecommendations(element: HTMLElement): NostoRecommendations | undefined {
    const nostoData = element.querySelector(`[${dynamicPlacementAttribute}]`);

    if (!nostoData?.textContent) {
        return undefined;
    }

    try {
        const sanitizedData = decode(nostoData.textContent);

        const parsedData = JSON.parse(sanitizedData);

        if (parsedData) {
            return normalizeNostoRecommendations({
                campaigns: {
                    recommendations: [parsedData],
                },
            } as NostoRecommendationsRaw);
        }
    } catch (error) {
        console.error('Failed to parse Dynamic Nosto data', error);
    }

    return undefined;
}

export function hasRecommendationsData(element: HTMLElement): boolean {
    return element.querySelector(`[${dynamicPlacementAttribute}]`) !== null;
}
