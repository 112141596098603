import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import classnames from 'classnames';
import { useClickOutside } from '@wearejh/react-hooks/lib/useClickOutside';
import { useDispatch } from 'react-redux';
import { useCartItemApi } from '@wearejh/m2-pwa-cart-gql/lib/hooks/useCartItemApi';

import { useHistoryLocationObservable } from 'src/hooks/router-hooks';
import { useDeps } from 'src/hooks/useDeps';
import { getCart_getCart } from 'src/queries/__generated__/getCart';
import { MiniCart } from 'src/components/Header/MiniCart/MiniCart';
import { useBreakpointMatch } from 'src/hooks/useBreakpoint';

import { Svg } from '../Svg/Svg';

import classes from './HeaderControls.scss';
import { BasketBadgeMemo } from './BasketBadge';

export interface BasketIconProps {
    /** The number of items in the basket/cart */
    basketItemCount: number;

    /**
     * Action to close the mini cart.
     */
    closeMiniCart(): void;

    /**
     * Mini cart open/closed state
     */
    isMiniCartOpen: boolean;

    /**
     * The maximum number before the item count is clipped.
     * @example itemCount under the maxBadgeCount
     * <BasketIcon basketItemCount={5} maxBadgeCount={10}>
     * Basket count would display '5 items in cart'
     *
     * @example itemCount over the maxBadgeCount
     * <BasketIcon basketItemCount={15} maxBadgeCount={10}>
     * Basket count would display '10+ items in cart'
     */
    maxBadgeCount: number;

    /**
     * Predicate used in `useClickOutside` to
     * determine if click handler is required.
     */
    miniCartClickPredicate: boolean;

    /**
     * Action to toggle the opening and closing
     * of the mini cart.
     */
    toggleMiniCart(event: any): void;
}

/**
 * Default prop values used  by `<BasketIcon />`
 */
export const basketIconDefaultProps: Pick<
    BasketIconProps,
    'closeMiniCart' | 'isMiniCartOpen' | 'maxBadgeCount' | 'toggleMiniCart'
> = {
    closeMiniCart: () => {},
    isMiniCartOpen: false,
    maxBadgeCount: 99,
    toggleMiniCart: () => {},
};

/**
 * Header Basket Icon
 */
export function BasketIcon(props: BasketIconProps) {
    const { basketItemCount, maxBadgeCount } = props;

    const hasBasketItems = basketItemCount > 0;

    /**
     * Label to be displayed in `<BasketBadge />` and
     * within the accessible label.
     */
    const basketBadgeLabel = basketItemCount > maxBadgeCount ? `${maxBadgeCount}+` : `${basketItemCount}`;

    /**
     * Plural or singular of `item(s)`
     * @returns 'item' | 'items'
     */
    const pluralItemLabel = `item${basketItemCount > 1 || !hasBasketItems ? 's' : ''}`;

    /** Accessible label used by assistive web technology */
    const ariaBasketLabel = `${basketBadgeLabel} ${pluralItemLabel} in cart`;

    /**
     * Ref for tracking where the user clicks, so that
     * the mini cart is closed when the click is outside
     * of the component.
     */
    const ref = useRef(null);
    useClickOutside(ref, props.miniCartClickPredicate, props.closeMiniCart);

    return (
        <div style={{ position: 'relative' }} ref={ref}>
            <button
                aria-label={ariaBasketLabel}
                aria-live="polite"
                className={classnames({
                    [classes.headerControl]: true,
                    [classes.headerControlBasket]: true,
                })}
                onClick={props.toggleMiniCart}
                type="button"
            >
                <span className={classes.basketBadge__container} data-ui-has-items={hasBasketItems}>
                    <BasketBadgeMemo label={basketBadgeLabel} />
                </span>
                <Svg name="basket" />
                <span className={classes.headerControlContent}>Cart</span>
            </button>
            <MiniCart isCartOpen={props.isMiniCartOpen} closeMiniCart={props.closeMiniCart} />
        </div>
    );
}

BasketIcon.defaultProps = basketIconDefaultProps;

/**
 * A memorized (`React.memo()`) version of `<BasketIcon />`
 * to provide performance enhancements.
 *
 * Use `<BasketIcon />` if you don't want to use the
 * memorized version.
 */
export const BasketIconMemo = memo(BasketIcon);

/**
 * A wrapper which connects `<BasketIcon />` to the global store.
 *
 * This wrapper component also manages the `<BasketIcon />` state
 * with is integrated with the store.
 *
 * @returns Wrapped memorized version of `<BasketIcon />`
 */
export function ConnectedBasketIcon() {
    const { items } = useCartItemApi<getCart_getCart>();
    const { isDesk } = useBreakpointMatch();

    const dispatch = useDispatch();
    const { paths, push } = useDeps();
    const pathname = useHistoryLocationObservable();
    const basketPath = paths.basket.basket;

    /**
     * Track the open/close state of the minicart
     */
    const [isMiniCartOpen, setIsMiniCartOpen] = useState(false);
    const closeMiniCart = useCallback(() => setIsMiniCartOpen(false), []);

    /**
     * When the URL changes, always hide the minicart
     */
    useEffect(() => {
        if (!isDesk) return;
        const sub = pathname?.subscribe(() => setIsMiniCartOpen(false));
        return () => sub?.unsubscribe();
    }, [isDesk, pathname]);

    const toggleMiniCart = useCallback(
        (event) => {
            event.preventDefault();

            if (isDesk) {
                setIsMiniCartOpen((x) => !x);
            } else {
                dispatch(push(basketPath));
            }
        },
        [dispatch, isDesk, basketPath, push, setIsMiniCartOpen],
    );

    /**
     * Get total of all items in basket
     */
    const allBasketItemsCount = items.reduce((total, item) => {
        return total + item.quantity;
    }, 0);

    return (
        <BasketIconMemo
            basketItemCount={allBasketItemsCount}
            closeMiniCart={closeMiniCart}
            isMiniCartOpen={isMiniCartOpen}
            miniCartClickPredicate={isDesk}
            toggleMiniCart={toggleMiniCart}
        />
    );
}
