import React from 'react';

import { ProductLabelImage } from 'src/components/ProductLabels/components/ProductLabelImage';
import { ProductLabelLocation, ProductLabelText } from 'src/components/ProductLabels/components/ProductLabelText';
import { ProductLabelData } from 'src/components/ProductLabels/utils/cleanLabelData';

import classes from './ProductLabelsList.scss';

export type ProductLabelsTypeProps = {
    label: ProductLabelData;
    locationVariant?: ProductLabelLocation;
};

/**
 * Group of product labels
 */
export function ProductLabelsType(props: ProductLabelsTypeProps) {
    switch (props.label.type) {
        case 'picture':
            if (props.label.img_file && props.label.name) {
                return (
                    <li className={classes.productLabelsList__item}>
                        <ProductLabelImage imageSrc={props.label.img_file} imageAlt={props.label.name} />
                    </li>
                );
            }

            return null;

        default:
            if (props.label.name) {
                return (
                    <li className={classes.productLabelsList__item}>
                        <ProductLabelText
                            label={props.label.name}
                            customizeCssContainer={props.label.customize_css_container_large ?? undefined}
                            customizeCssLabel={props.label.customize_css_label_large ?? undefined}
                            locationVariant={props.locationVariant}
                        />
                    </li>
                );
            }

            return null;
    }
}

export default ProductLabelsType;
